.disclose__toggle {
	.ex-text-md();
	display: none; // We hide this by default (when no JS available)
	text-shadow: none;
	background: transparent;
	padding: 0;
	margin: 0;
	border: 0;
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;

	// When the disclose-toggle has the correct
	// aria attribute set via JS, we can show it
	&[aria-expanded] {
		display: inline-flex;
		align-items: center;
	}

	&[aria-expanded='true'] svg {
		transform: rotate(-180deg);
	}

	&:hover,
	&:focus {
		text-decoration: underline !important;
		background-color: transparent;
		text-shadow: none;
	}

	&:focus {
		text-decoration: none;
		// outline: 2px solid rgba(255, 255, 255, 0.4);
		// outline-offset: 0.6rem;
	}

	&:active {
		// transform: scale(0.99);
	}

	svg {
		margin-left: 0.5rem;
		transition: all 250ms ease-in-out;
		font-size: 0.8rem;
	}
}

// The disclose component which is completely controlled by a valid sibling
// button element, such as the link button. We only conceal the content
// if its sibling button has the correct aria attribute, so we can enforce
// accessibility and progressively enhance the UI
[aria-expanded] + .disclose__panel {
	--disclose-max-height: 500px;
	transition: all 200ms ease;
	position: relative;
	// overflow-y: auto;
	// overflow-x: hidden;
	overflow: hidden;
	max-height: 0;
	visibility: hidden;
	-webkit-overflow-scrolling: touch;
}

[aria-expanded] + .disclose__panel .disclose__inner {
	transition: all 500ms ease;
	transition-delay: 50ms;
	opacity: 0;
	transform: translateY(1rem);
	padding-top: 1.5rem;
}

[aria-expanded='true'] + .disclose__panel {
	max-height: var(--disclose-max-height);
	visibility: visible;
}

[aria-expanded='true'] + .disclose__panel .disclose__inner {
	opacity: 1;
	transform: translateY(0);
}
