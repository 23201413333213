// ------------------------------------
//
// Mixins
//
// ------------------------------------

// ------------------------------------
// Media query helper
// ------------------------------------

// See: https://gist.github.com/vestman/6237abef54322a73f782

// Example:
// --------
// .selector {
//     ...
//     .ex-mq(sm; {
//         width: 100px;
//     });
// }
// Output:
// .selector {
//     ...
// }
// @media screen and (min-width: 768px) {
//     .selector {
//         width: 100px;
//     }
// }
//
// Example using max width:
// ------------------------
// .selector {
//     ...
//     .ex-mq(- sm; {
//         width: 100px;
//     });
// }
// Output:
// .selector {
//     ...
// }
// @media screen and (max-width: 767px) {
//     .selector {
//         width: 100px;
//     }
// }
//
// Example using min and max width:
// --------------------------------
// .selector {
//     ...
//     .ex-mq(sm md; {
//         width: 100px;
//     });
// }
// Output:
// .selector {
//     ...
// }
// @media screen and (min-width: 768px) and (max-width: 991px) {
//     .selector {
//         width: 100px;
//     }
// }
//

.ex-mq(@breakpoints; @rules;) {
	// If there's only one breakpoint specified
	& when (length(@breakpoints) = 1) {
		@query: ~'(min-width: @{ex-breakpoint-@{breakpoints}})';

		@media screen and @query {
			@rules();
		}
	}

	// If there's two breakpoints specified
	& when (length(@breakpoints) = 2) {
		@bpMin: extract(@breakpoints, 1);
		@maxVar: extract(@breakpoints, 2);
		@tmpMax: ~'ex-breakpoint-@{maxVar}';
		@bpMax: (@@tmpMax - 1);

		// If the first "breakpoint" = -, then build a max-width query
		& when (@bpMin = -) {
			@query: ~'(max-width: @{bpMax})';
			@media screen and @query {
				@rules();
			}
		}

		// If the first breakpoint is something else than "-",
		// then build a min- and max-width query
		& when not (@bpMin = -) {
			@query: ~'(min-width: @{ex-breakpoint-@{bpMin}}) and (max-width: @{bpMax})';
			@media screen and @query {
				@rules();
			}
		}
	}
}

// ------------------------------------
// Padded text effect
// ------------------------------------

.padded-text(@color: @ex-color-white, @bg: @ex-color-yellow, @padding: 0) {
	margin-top: (-@padding / 2);
	// margin-left: @padding;

	span,
	a {
		background: @bg;
		box-decoration-break: clone; // FF fix
		box-shadow: @padding 0 0 @bg, -@padding 0 0 @bg;
		color: @color;
		display: inline;
		padding: (@padding / 2) @padding (@padding / 2) 0;
		// padding: 0 @padding 0 @padding;
	}
}

// ------------------------------------
// Full-width background
// ------------------------------------

.full-width-background(@background, @z-index: -1) {
	overflow: visible;
	position: relative;

	&::after {
		background-color: @background;
		bottom: -1px;
		content: '';
		display: block;
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: 100vw;
		z-index: @z-index;
	}
}

// ------------------------------------
// Generate color theme
// ------------------------------------

.generate-theme(@color, @bg, @heading-color, @heading-bg, @ex-button-color, @ex-button-bg, @custom-1, @custom-2) {
	//
	.ex-full-width-bg {
		color: @color;

		&::after {
			background-color: @bg;
		}
	}

	// Background color (svg fill) is set via 'color'
	.ex-cutout {
		color: @bg;
	}

	// Set the background color (not fill!) of the following cutout to
	// this cutout's color in order to create the 'cutout' illusion
	& + div > .ex-cutout {
		background-color: @bg;
	}

	.ex-heading-1 {
		.padded-text(@color: @heading-color, @bg: @heading-bg, @padding: 0);
	}

	.ex-heading-2 {
		.padded-text(@color: @heading-color, @bg: @heading-bg, @padding: 0.125em);
	}

	.ex-heading-1,
	.ex-heading-2 {
		a {
			&:hover,
			&:active,
			&:focus {
				color: @ex-button-color;
				background-color: @ex-button-bg;
			}
		}
	}

	.ex-button,
	.ex-read-more {
		color: @ex-button-color;
		background-color: @ex-button-bg;

		&:hover,
		&:active,
		&:focus {
			color: @ex-button-color;
			background-color: darken(@ex-button-bg, 25%);
		}
	}

	.ex-disclose-toggle {
	}

	// --------------------------------
	// Flip
	// --------------------------------

	.ex-flip {
		box-shadow: 0 0 0 0.5rem @bg, 0 0 0 1rem @ex-color-green-dark;
	}

	.ex-flip-heading {
		color: @custom-1;

		.ex-flip-color-2 & {
			color: @custom-1;
		}
	}

	.ex-flip-front {
		background-color: @custom-2;
	}

	// --------------------------------
	// Hinge
	// --------------------------------

	.ex-hinge-back {
		color: @color;
		background-color: darken(@bg, 10%);
	}

	.ex-hinge-leaf {
		color: @heading-bg;
	}

	.ex-hinge-color-2 .ex-hinge-leaf {
		color: @custom-1;
	}

	.ex-hinge-color-3 .ex-hinge-leaf {
		color: @custom-2;
	}

	// --------------------------------
	// Pull-outs
	// --------------------------------

	.ex-pullout-heading {
		.padded-text(@color: @heading-color, @bg: @heading-bg, @padding: 0);
	}

	.ex-pullout-back {
		background-color: @heading-bg;

		p {
			color: @heading-color;
		}
	}

	.ex-pullout-scrub {
		color: @custom-1;
	}

	// --------------------------------
	// Spin
	// --------------------------------

	.ex-spinner-heading {
		.padded-text(@color: @heading-bg, @bg: @heading-color, @padding: 0);
	}

	.ex-spinner-spiderweb {
		color: @color;
	}

	.ex-spinner-back {
		color: @heading-color;
		background-color: @heading-bg;
	}
}
