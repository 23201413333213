@keyframes magnify-particle-shake {
	2% {
		transform: rotate(2.5deg) translate3d(-50%, -50%, 0);
	}

	4% {
		transform: rotate(-0.5deg) translate3d(-50%, -50%, 0);
	}

	6% {
		transform: rotate(0.5deg) translate3d(-50%, -50%, 0);
	}

	8% {
		transform: rotate(-1.5deg) translate3d(-50%, -50%, 0);
	}

	10% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	12% {
		transform: rotate(2.5deg) translate3d(-50%, -50%, 0);
	}

	14% {
		transform: rotate(-1.5deg) translate3d(-50%, -50%, 0);
	}

	16% {
		transform: rotate(-2.5deg) translate3d(-50%, -50%, 0);
	}

	18% {
		transform: rotate(-0.5deg) translate3d(-50%, -50%, 0);
	}

	20% {
		transform: rotate(3.5deg) translate3d(-50%, -50%, 0);
	}

	22% {
		transform: rotate(-2.5deg) translate3d(-50%, -50%, 0);
	}

	24% {
		transform: rotate(0.5deg) translate3d(-50%, -50%, 0);
	}

	26% {
		transform: rotate(-0.5deg) translate3d(-50%, -50%, 0);
	}

	28% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	30% {
		transform: rotate(3.5deg) translate3d(-50%, -50%, 0);
	}

	32% {
		transform: rotate(-2.5deg) translate3d(-50%, -50%, 0);
	}

	34% {
		transform: rotate(-0.5deg) translate3d(-50%, -50%, 0);
	}

	36% {
		transform: rotate(0.5deg) translate3d(-50%, -50%, 0);
	}

	38% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	40% {
		transform: rotate(3.5deg) translate3d(-50%, -50%, 0);
	}

	42% {
		transform: rotate(0.5deg) translate3d(-50%, -50%, 0);
	}

	44% {
		transform: rotate(-1.5deg) translate3d(-50%, -50%, 0);
	}

	46% {
		transform: rotate(-0.5deg) translate3d(-50%, -50%, 0);
	}

	48% {
		transform: rotate(2.5deg) translate3d(-50%, -50%, 0);
	}

	50% {
		transform: rotate(-1.5deg) translate3d(-50%, -50%, 0);
	}

	52% {
		transform: rotate(-1.5deg) translate3d(-50%, -50%, 0);
	}

	54% {
		transform: rotate(-2.5deg) translate3d(-50%, -50%, 0);
	}

	56% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	58% {
		transform: rotate(-2.5deg) translate3d(-50%, -50%, 0);
	}

	60% {
		transform: rotate(-1.5deg) translate3d(-50%, -50%, 0);
	}

	62% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	64% {
		transform: rotate(-1.5deg) translate3d(-50%, -50%, 0);
	}

	66% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	68% {
		transform: rotate(-1.5deg) translate3d(-50%, -50%, 0);
	}

	70% {
		transform: rotate(0.5deg) translate3d(-50%, -50%, 0);
	}

	72% {
		transform: rotate(-2.5deg) translate3d(-50%, -50%, 0);
	}

	74% {
		transform: rotate(0.5deg) translate3d(-50%, -50%, 0);
	}

	76% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	78% {
		transform: rotate(3.5deg) translate3d(-50%, -50%, 0);
	}

	80% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	82% {
		transform: rotate(-2.5deg) translate3d(-50%, -50%, 0);
	}

	84% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	86% {
		transform: rotate(2.5deg) translate3d(-50%, -50%, 0);
	}

	88% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	90% {
		transform: rotate(3.5deg) translate3d(-50%, -50%, 0);
	}

	92% {
		transform: rotate(1.5deg) translate3d(-50%, -50%, 0);
	}

	94% {
		transform: rotate(-2.5deg) translate3d(-50%, -50%, 0);
	}

	96% {
		transform: rotate(-0.5deg) translate3d(-50%, -50%, 0);
	}

	98% {
		transform: rotate(3.5deg) translate3d(-50%, -50%, 0);
	}

	0%,
	100% {
		transform: translate(0, 0) rotate(0) translate3d(-50%, -50%, 0);
	}
}
