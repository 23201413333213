// ------------------------------------
//
// Pullout Paper
//
// ------------------------------------

@pullout-scrub-width-lg: 16rem;
@pullout-scrub-width-md: 14rem;
@pullout-scrub-width-sm: 12rem;

@pullout-heading-offset: 8rem;

.ex-pullout-col {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row-reverse;
	flex-wrap: wrap;

	.ex-mq(sm; {
        padding-right: @pullout-scrub-width-sm - 1rem;
	});

	.ex-mq(md; {
        padding-right: @pullout-scrub-width-md - 1rem;
	});

	.ex-mq(lg; {
		align-items: flex-end;
        padding-right: @pullout-scrub-width-lg - 1rem;
	});
}

.ex-pullout-scrub {
	width: 100%;
	height: auto;
	vertical-align: top;
}

.ex-pullout {
	flex: 0 0 @pullout-scrub-width-sm;
	width: @pullout-scrub-width-sm;
	cursor: pointer;
	position: relative;
	display: block;
	z-index: 999;
	margin-left: 1rem;
	margin-right: 1rem;
	margin-bottom: @pullout-heading-offset;
	z-index: @ex-elevation-task;

	.ex-mq(md; {
        flex: 0 0 @pullout-scrub-width-md;
        width: @pullout-scrub-width-md;
	});

	.ex-mq(lg; {
        flex: 0 0 @pullout-scrub-width-lg;
        width: @pullout-scrub-width-lg;
        margin-left: 4rem !important;
	    margin-right: 4rem !important;
        margin-bottom: @pullout-heading-offset + 2rem;
	});

	&.is-pulled-out {
		z-index: @ex-elevation-task-open;
	}

	&:nth-child(even) {
		margin-right: auto;

		.ex-mq(lg; {
			margin-right: 1rem;
		});
	}

	&:nth-child(odd) {
		margin-left: auto;

		.ex-mq(lg; {
			margin-left: 1rem;
		});
	}
}

.ex-pullout-front {
	width: 100%;
	padding: 0 1.5rem;
}

.ex-pullout-back {
	padding: 1rem;
	position: absolute;
	top: 0;
	right: 0%;
	width: 100%;
	height: auto;
	z-index: -1;
	transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform: scale(0.5) translate(20%, -10%) rotate(20deg);

	.ex-mq(lg; {
        width: 94%;
	});

	.ex-pullout-pos-2 & {
		transform: scale(0.5) translate(0%, -15%) rotate(10deg);
	}

	.ex-pullout-pos-3 & {
		transform: scale(0.5) translate(-25%, 0%) rotate(-15deg);
	}

	// Hovered state

	.ex-pullout:hover:not(.is-pulled-out) & {
		transform: scale(0.55) translate(20%, -15%) rotate(15deg);
	}

	.ex-pullout-pos-2:hover:not(.is-pulled-out) & {
		transform: scale(0.55) translate(0%, -20%) rotate(5deg);
	}

	.ex-pullout-pos-3:hover:not(.is-pulled-out) & {
		transform: scale(0.55) translate(-20%, -5%) rotate(-10deg);
	}

	// Pulled-out state

	.is-pulled-out & {
		box-shadow: @ex-task-shadow-current;
		z-index: (@ex-elevation-task-open + 1);
		transform: scale(1) translate(0, 0) rotate(-3deg);

		.ex-mq(sm; {
            transform: scale(1) translate(80%, 0) rotate(-3deg);
        });
	}

	.ex-pullout-pos-2.is-pulled-out & {
		transform: scale(1) translate(0, 0) rotate(-5deg);

		.ex-mq(sm; {
            transform: scale(1) translate(80%, 0) rotate(-5deg);
        });
	}

	.ex-pullout-pos-3.is-pulled-out & {
		transform: scale(1) translate(0, 0) rotate(3deg);

		.ex-mq(sm; {
            transform: scale(1) translate(80%, -40%) rotate(3deg);
        });
	}

	.ex-text-md {
		margin-bottom: 0;
	}
}

.ex-pullout-heading {
	.ex-heading-size-5;
	line-height: 1.2 !important;
	margin-bottom: 0;
	position: absolute;
	left: 0;
	top: 100%;
	transform: translateY(-1.2em);
}
