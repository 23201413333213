// ------------------------------------
//
// Teaser at the bottom of each page
//
// ------------------------------------

.ex-teaser-set {
	align-items: stretch;
}

.ex-teaser {
	margin-bottom: 4rem;
}

.ex-teaser-heading {
	.padded-text(@color: @ex-color-white, @bg: @ex-color-yellow, @padding: 0);
	.ex-heading-size-2();
	line-height: 1.3;
	text-transform: uppercase;
	margin-bottom: 0.5em;

	a {
		&:hover,
		&:active,
		&:focus {
			background-color: @ex-color-yellow-dark;
		}
	}

	.ex-teaser-consumer-rights & {
		.padded-text(@color: @ex-color-white, @bg: @ex-color-red);

		a {
			&:hover,
			&:active,
			&:focus {
				background-color: @ex-color-red-dark;
			}
		}
	}

	.ex-teaser-digital & {
		.padded-text(@color: @ex-color-white, @bg: @ex-color-yellow);

		a {
			&:hover,
			&:active,
			&:focus {
				background-color: @ex-color-yellow-dark;
			}
		}
	}

	.ex-teaser-finance & {
		.padded-text(@color: @ex-color-white, @bg: @ex-color-blue);

		a {
			&:hover,
			&:active,
			&:focus {
				background-color: @ex-color-blue-dark;
			}
		}
	}

	.ex-teaser-insurance & {
		.padded-text(@color: @ex-color-white, @bg: @ex-color-purple);

		a {
			&:hover,
			&:active,
			&:focus {
				background-color: @ex-color-purple-dark;
			}
		}
	}

	.ex-teaser-telecom & {
		.padded-text(@color: @ex-color-white, @bg: @ex-color-orange);

		a {
			&:hover,
			&:active,
			&:focus {
				background-color: @ex-color-orange-dark;
			}
		}
	}
}

.ex-teaser-text {
	.ex-text-md;
	max-width: 32rem;
}
