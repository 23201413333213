// ------------------------------------
//
// Typography
//
// ------------------------------------

.ex-heading-base() {
	padding: 0;
	margin-left: 0;
	margin-right: 0;
	font-weight: 700;
}

.ex-heading-size-1() {
	.ex-heading-base();
	font-size: 1.5rem;
	line-height: 1.3;

	.ex-mq(sm; {
		font-size: 2rem;
		line-height: 1.3;
	});

	.ex-mq(lg; {
		font-size: 2.75rem;
		line-height: 1.3;
	});
}

.ex-heading-size-2() {
	.ex-heading-base();
	font-size: 1.25rem;
	line-height: 1.3;

	.ex-mq(md; {
		font-size: 1.75rem;
		line-height: 1.3;
	});

	.ex-mq(lg; {
		font-size: 2.5rem;
		line-height: 1.3;
	});
}

.ex-heading-size-3() {
	.ex-heading-base();
	font-size: 1.125rem;
	line-height: 1.4;

	.ex-mq(md; {
		font-size: 1.5rem;
		line-height: 1.25;
	});

	.ex-mq(lg; {
		font-size: 2rem;
		line-height: 1.25;
	});
}

.ex-heading-size-4() {
	.ex-heading-base();
	font-size: 1rem;
	line-height: 1.4;

	.ex-mq(md; {
		font-size: 1.25rem;
		line-height: 1.4;
	});

	.ex-mq(lg; {
		font-size: 1.75rem;
		line-height: 1.25;
	});
}

.ex-heading-size-5() {
	.ex-heading-base();
	font-size: 1rem;
	line-height: 1.4;

	.ex-mq(md; {
		font-size: 1.125rem;
		line-height: 1.4;
	});

	.ex-mq(lg; {
		font-size: 1.4rem;
		line-height: 1.4;
	});
}

.ex-heading-size-6() {
	.ex-heading-base();
	font-size: 1rem;
	line-height: 1.4;
}

.ex-heading-1 {
	.ex-heading-size-1();
	.padded-text(@color: @ex-color-white, @bg: @ex-color-yellow, @padding: 0);
	text-transform: uppercase;
	margin-bottom: 1em;
}

.ex-heading-2 {
	.ex-heading-size-2();
	.padded-text(@color: @ex-color-white, @bg: @ex-color-yellow, @padding: 0.125em);
	margin-bottom: 1em;
}

.ex-heading-3 {
	.ex-heading-size-3();
	margin-bottom: 0.5em;
}

.ex-heading-4 {
	.ex-heading-size-4();
	margin-bottom: 0.5em;
}

.ex-heading-5 {
	.ex-heading-size-5();
	margin-bottom: 0.5em;
}

.ex-heading-6 {
	.ex-heading-size-6();
	margin-bottom: 0.5em;
}

// Linked (padded) headings

.ex-heading-1,
.ex-heading-2 {
	a {
		&:hover,
		&:active,
		&:focus {
			background-color: @ex-color-yellow-dark;
		}
	}
}

.ex-text-base() {
	font-family: 'Zilla Slab';
	font-weight: 500;
	padding: 0;
	margin-bottom: 0.75em;
	line-height: 1.5;
}

.ex-text-xs {
	.ex-text-base();
	font-size: 0.75rem;

	.ex-mq(sm; {
		font-size: 0.875rem;
	});

	.ex-mq(lg; {
		font-size: 0.875rem;
	});
}

.ex-text-sm {
	.ex-text-base();
	font-size: 0.75rem;

	.ex-mq(sm; {
		font-size: 0.875rem;
	});

	.ex-mq(lg; {
		font-size: 1rem;
	});
}

.ex-text-md {
	.ex-text-base();
	font-size: 0.875rem;

	.ex-mq(sm; {
		font-size: 0.9375rem;
	});

	.ex-mq(lg; {
		font-size: 1.125rem;
	});
}

.ex-text-lg {
	.ex-text-base();
	font-size: 1rem;

	.ex-mq(sm; {
		font-size: 1.25rem;
	});

	.ex-mq(lg; {
		font-size: 1.5rem;
		line-height: 1.4;
	});
}

// Faux heading outdent

.ex-outdent-heading {
	.ex-mq(md; {
		padding-left: (@ex-grid-gutter-md * 3) !important;
	});

	.ex-mq(xl; {
		padding-left: (@ex-grid-gutter-lg * 4) !important;
	});

	> .ex-heading-1,
	> .ex-heading-2 {
		.ex-mq(md; {
			margin-left: (@ex-grid-gutter-md * -2) !important;
		});

		.ex-mq(xl; {
			margin-left: (@ex-grid-gutter-lg * -3) !important;
		});
	}
}

.ex-text-link {
	text-decoration: underline;

	&:focus,
	&:hover,
	&:active {
		color: @ex-color-red;
	}
}

// List

.ex-list-sm,
.ex-list-md {
	max-width: 100%;
}

.ex-list-sm {
	.ex-text-base();
	font-size: 0.875rem;

	.ex-mq(md; {
		font-size: 1rem;
	});
}

.ex-list-md {
	.ex-text-base();
	font-size: 0.875rem;

	.ex-mq(md; {
		font-size: 1.25rem;
	});
}

.ex-list-sm,
.ex-list-md {
	p + & {
		margin-top: 0.75em;
	}
}

.ex-list-item-sm,
.ex-list-item-md {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	margin: 0;
	padding-left: 0;
}
