// ------------------------------------
//
// Color themes
//
// ------------------------------------

.ex-theme-start {
	.generate-theme(
		@ex-color-black,
		@ex-color-white,
		@ex-color-black,
		transparent,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-end {
	.generate-theme(
		@ex-color-black,
		@ex-color-gray,
		@ex-color-gray,
		@ex-color-black,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

// Telecom

.ex-theme-telecom-1 {
	.generate-theme(
		@ex-color-black,
		@ex-color-gray,
		@ex-color-gray,
		@ex-color-orange,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-telecom-2 {
	.generate-theme(
		@ex-color-white,
		@ex-color-green,
		@ex-color-white,
		@ex-color-orange,
		@ex-color-white,
		@ex-color-green-dark,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-telecom-3 {
	.generate-theme(
		@ex-color-white,
		@ex-color-orange,
		@ex-color-orange,
		@ex-color-white,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-telecom-4 {
	.generate-theme(
		@ex-color-green-dark,
		@ex-color-beige,
		@ex-color-white,
		@ex-color-orange,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

// Digital

.ex-theme-digital-1 {
	.generate-theme(
		@ex-color-black,
		@ex-color-white,
		@ex-color-white,
		@ex-color-yellow,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-digital-2 {
	.generate-theme(
		@ex-color-white,
		@ex-color-green,
		@ex-color-white,
		@ex-color-yellow,
		@ex-color-white,
		@ex-color-red,
		@ex-color-green-dark,
		@ex-color-yellow
	);
}

.ex-theme-digital-3 {
	.generate-theme(
		@ex-color-black,
		@ex-color-yellow,
		@ex-color-yellow,
		@ex-color-white,
		@ex-color-white,
		@ex-color-orange-dark,
		@ex-color-white,
		@ex-color-green
	);
}

.ex-theme-digital-4 {
	.generate-theme(
		@ex-color-green-dark,
		@ex-color-green-light,
		@ex-color-white,
		@ex-color-yellow,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

// Consumer rights

.ex-theme-consumer-rights-1 {
	.generate-theme(
		@ex-color-black,
		@ex-color-white,
		@ex-color-white,
		@ex-color-red,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-consumer-rights-2 {
	.generate-theme(
		@ex-color-white,
		@ex-color-green,
		@ex-color-white,
		@ex-color-red,
		@ex-color-white,
		@ex-color-green-dark,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-consumer-rights-3 {
	.generate-theme(
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-white,
		@ex-color-white,
		@ex-color-red,
		@ex-color-green,
		@ex-color-beige
	);
}

.ex-theme-consumer-rights-4 {
	.generate-theme(
		@ex-color-green-dark,
		@ex-color-beige,
		@ex-color-white,
		@ex-color-red,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

// Finance

.ex-theme-finance-1 {
	.generate-theme(
		@ex-color-black,
		@ex-color-white,
		@ex-color-white,
		@ex-color-blue,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-finance-2 {
	.generate-theme(
		@ex-color-white,
		@ex-color-green,
		@ex-color-white,
		@ex-color-blue,
		@ex-color-white,
		@ex-color-red,
		@ex-color-white,
		@ex-color-green-light
	);
}

.ex-theme-finance-3 {
	.generate-theme(
		@ex-color-white,
		@ex-color-blue,
		@ex-color-blue,
		@ex-color-white,
		@ex-color-white,
		@ex-color-red,
		@ex-color-green,
		@ex-color-green-light
	);
}

.ex-theme-finance-4 {
	.generate-theme(
		@ex-color-green-dark,
		@ex-color-green-light,
		@ex-color-white,
		@ex-color-blue,
		@ex-color-white,
		@ex-color-green,
		@ex-color-green,
		@ex-color-white
	);
}

// Insurance

.ex-theme-insurance-1 {
	.generate-theme(
		@ex-color-black,
		@ex-color-white,
		@ex-color-white,
		@ex-color-purple,
		@ex-color-white,
		@ex-color-red,
		@ex-color-red,
		@ex-color-orange
	);
}

.ex-theme-insurance-2 {
	.generate-theme(
		@ex-color-white,
		@ex-color-green,
		@ex-color-white,
		@ex-color-purple,
		@ex-color-white,
		@ex-color-red,
		@ex-color-beige,
		@ex-color-orange
	);
}

.ex-theme-insurance-3 {
	.generate-theme(
		@ex-color-white,
		@ex-color-purple,
		@ex-color-purple,
		@ex-color-white,
		@ex-color-white,
		@ex-color-purple-dark,
		@ex-color-green,
		@ex-color-orange
	);
}

.ex-theme-insurance-4 {
	.generate-theme(
		@ex-color-green-dark,
		@ex-color-beige,
		@ex-color-white,
		@ex-color-purple,
		@ex-color-white,
		@ex-color-red,
		@ex-color-green,
		@ex-color-orange
	);
}


