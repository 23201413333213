// ------------------------------------
//
// Flipping Flan
//
// ------------------------------------

.ex-flip-col {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.ex-flip {
	flex: 0 0 auto;
	cursor: pointer;
	perspective: 1000px;
	z-index: @ex-elevation-task;
	position: relative;
	display: block;
	border-radius: 50%;
	box-shadow: 0 0 0 0.5rem @ex-color-green, 0 0 0 1rem @ex-color-green-dark;
	width: 17rem;
	max-width: 100%;
	margin: 2rem;

	.ex-mq(md; {
		width: 18rem;
	});

	.ex-mq(lg; {
		margin: 1.5rem 2rem;
	});

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	&.is-flipped {
		z-index: @ex-elevation-task-open;
	}

	.ex-text-xs,
	.ex-text-md {
		margin-bottom: 0;
	}
}

.ex-flip-sm {
	width: 15rem;

	.ex-mq(md; {
		width: 16rem;
	});
}

.ex-flip-lg {
	width: 18rem;

	.ex-mq(md; {
		width: 21rem;
	});
}

.ex-flip-front,
.ex-flip-back {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	text-align: center;
	padding: 0 10%;
	border-radius: 50%;

	overflow: hidden;

	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;

	transform-style: preserve-3d;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.ex-flip-image {
	width: 2rem;
	margin: 0.75rem auto -2.5rem;
}

.ex-flip-front {
	background-color: @ex-color-yellow;

	.ex-flip-color-2 & {
		background-color: @ex-color-white;
	}

	.ex-flip-color-3 & {
		background-color: @ex-color-green-light;
	}

	// Flipped

	.ex-flip.is-flipped & {
		transform: rotateY(180deg);
	}

	// Hover

	.ex-flip:not(.is-flipped):hover & {
		transform: rotateY(15deg);
	}
}

.ex-flip-back {
	background-color: @ex-color-white;
	transform: rotateY(-180deg);

	.ex-text-xs,
	.ex-text-md {
		color: @ex-color-green-dark;
	}

	// Flipped

	.ex-flip.is-flipped & {
		transform: rotateY(0deg);
	}

	// Hover

	.ex-flip.is-flipped:hover & {
		transform: rotateY(-15deg);
	}
}

.ex-flip-heading {
	.ex-heading-size-5();
	font-size: 1.4rem !important;
	line-height: 1.4 !important;
	text-align: center;
	color: @ex-color-green-dark;

	.ex-flip-color-2 & {
		color: @ex-color-green-dark;
	}

	.ex-flip-color-3 & {
		color: @ex-color-green-dark;
	}
}
