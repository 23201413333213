// ------------------------------------
//
// Components
//
// ------------------------------------

#breadcrumb.ex-breadcrumb {
	padding: 20px 0 80px;
	margin-top: 0;
}

.ex-hero {
	padding-top: 2rem;
	padding-bottom: 2rem;

	.ex-mq(lg; {
		min-height: 40vh;
		padding-top: 0;
		padding-bottom: 0;
	});
}

.ex-full-width-bg {
	.full-width-background(#fff);
	padding-top: 2rem;
	padding-bottom: 2rem;
	position: relative;

	.ex-mq(lg; {
		min-height: 40vh;
		padding-top: 0;
		padding-bottom: 0;
	});
}

.ex-section {
	padding-top: @ex-spacing-xl;
	padding-bottom: @ex-spacing-xl;
}

.ex-button,
.ex-read-more {
	padding: 0.5em 0.75em;
	display: inline-block;
	font-size: 1.0125rem;
	line-height: 1;
	font-weight: 700;
	color: @ex-color-white;
	background-color: @ex-color-red;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:hover,
	&:active,
	&:focus {
		background-color: @ex-color-yellow-dark;
	}
}

.ex-read-more {
	margin-top: 2rem;
	background-image: url('/assets/images/icon_arrow_right_white.svg');
	background-repeat: no-repeat;
	padding-right: 1.25em;
	background-position: right 0.5em center;
	background-size: 0.25em auto;
}

.ex-cutout {
	display: block;
	width: 100%;
	height: auto;
	// transform: scaleY(1.01);
}

.ex-container {
	position: relative;
}

.ex-scope {
	position: relative;
	overflow: hidden;
}
