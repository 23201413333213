// ------------------------------------
//
// Parallax leaves
//
// ------------------------------------

.ex-leaf-rounded-left,
.ex-leaf-rounded-right,
.ex-leaf-spiky-left,
.ex-leaf-spiky-right {
	display: none;
	width: 40rem;
	z-index: @ex-elevation-leaf;
	position: absolute;
	color: @ex-color-purple;

	.ex-mq(xl; {
		display: block;
	});

	> svg {
		filter: blur(0.75rem);
	}
}

.ex-leaf-rounded-right,
.ex-leaf-spiky-right {
	right: -30%;
}

.ex-leaf-rounded-left,
.ex-leaf-spiky-left {
	left: -30rem;
}

.ex-leaf-rounded-left > svg {
	transform: translate(-60%, 0) rotate(25deg);
	transform: translate(0, 0) rotate(25deg);
}

.ex-leaf-rounded-right > svg {
	// transform: translate(50%, 0) rotate(-20deg);
	transform: translate(0, 0) rotate(-20deg);
}

.ex-leaf-spiky-left > svg {
	// transform: translate(-50%, 0) rotate(10deg);
	transform: translate(0, 0) rotate(10deg);
}

.ex-leaf-spiky-right > svg {
	// transform: translate(60%, 0) rotate(-40deg);
	transform: translate(0, 0) rotate(-40deg);
}

// Page specific styles

.page-wanderausstellung {
	.ex-leaf-1 {
		top: 25%;
	}

	.ex-leaf-2 {
		color: @ex-color-green;
		top: 70%;
	}
}

.ex-page-consumer-rights {
	.ex-leaf-1 {
		top: 5%;
	}

	.ex-leaf-2 {
		top: 47%;
	}

	.ex-leaf-3 {
		top: 80%;
	}
}

.ex-page-digital {
	.ex-leaf-1 {
		top: 5%;
	}

	.ex-leaf-2 {
		color: @ex-color-green-dark;
		top: 25%;
	}

	.ex-leaf-3 {
		color: @ex-color-green-dark;
		top: 45%;
	}

	.ex-leaf-4 {
		top: 70%;
	}
}

.ex-page-finance {
	.ex-leaf-1 {
		color: @ex-color-green;
		top: 20%;
	}

	.ex-leaf-2 {
		color: @ex-color-green-dark;
		top: 35%;
	}

	.ex-leaf-3 {
		color: @ex-color-green-dark;
		top: 75%;
	}
}

.ex-page-telecom {
	.ex-leaf-1 {
		color: @ex-color-green-dark;
		top: 10%;
	}

	.ex-leaf-2 {
		top: 30%;
	}

	.ex-leaf-3 {
		color: @ex-color-green;
		top: 50%;
	}

	.ex-leaf-4 {
		color: @ex-color-green-dark;
		top: 70%;
	}
}

.ex-page-insurance {
	.ex-leaf-1 {
		color: @ex-color-green-dark;
		top: 12%;
	}

	.ex-leaf-2 {
		top: 18%;
	}
}
