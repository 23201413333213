// ------------------------------------
//
// Magnifying Minutia
//
// ------------------------------------

.ex-magnify-col {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.ex-magnify {
	flex: 0 0 auto;
	position: relative;
	display: block;
	z-index: @ex-elevation-task;
	margin: 0;
	width: 15rem;

	.ex-mq(md; {
        margin: 1rem;
    });

	&.is-magnified {
		z-index: @ex-elevation-task-open;
	}

	&:nth-child(even) {
		margin-right: auto;

		.ex-mq(md; {
			margin-right: 1rem;
		});
	}

	&:nth-child(odd) {
		margin-left: auto;

		.ex-mq(md; {
			margin-left: 1rem;
		});
	}

	&::before {
		content: '';
		padding-top: 100%;
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.ex-magnify-particle {
	z-index: (@ex-elevation-task + 1);
	cursor: pointer;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70%;
	transform: translate3d(-50%, -50%, 0);
	height: auto;
	transform-origin: top left;

	.ex-magnify:hover & {
		animation-name: magnify-particle-shake;
		animation-duration: 2s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;

		.ex-magnify:nth-of-type(2) & {
			animation-duration: 3s;
		}

		.ex-magnify:nth-of-type(3) & {
			animation-duration: 4s;
		}
	}

	.ex-magnify.is-magnified & {
		z-index: (@ex-elevation-task-open - 1);
	}
}

.ex-magnify-content {
	cursor: pointer;
	position: relative;
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform: translate3d(-30%, 0%, 0) scale(0.25);
	padding: 1rem;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	.ex-magnify:hover & {
		transform: translate3d(-30%, 0%, 0) scale(0.3);
	}

	.ex-magnify-pos-2 & {
		transform: translate3d(25%, -10%, 0) scale(0.25);
	}

	.ex-magnify-pos-2:hover & {
		transform: translate3d(25%, -10%, 0) scale(0.3);
	}

	.ex-magnify-pos-3 & {
		transform: translate3d(-30%, -10%, 0) scale(0.25);
	}

	.ex-magnify-pos-3:hover & {
		transform: translate3d(-30%, -10%, 0) scale(0.3);
	}

	.ex-magnify-pos-4 & {
		transform: translate3d(-30%, -20%, 0) scale(0.25);
	}

	.ex-magnify-pos-4:hover & {
		transform: translate3d(-30%, -20%, 0) scale(0.3);
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: @ex-color-green-dark;
		opacity: 0;
		z-index: -1;
	}

	.ex-magnify.is-magnified & {
		transform: translate3d(0, 0, 0) scale(1) !important;
		box-shadow: @ex-task-shadow-current;
		z-index: (@ex-elevation-task-open + 1);

		&::before {
			opacity: 1;
		}
	}
}
