// ------------------------------------
//
// Minimal grid system
//
// ------------------------------------

.ex-container {
	width: 100%;
	max-width: 1264px;
	padding-left: @ex-grid-gutter-lg;
	padding-right: @ex-grid-gutter-lg;
	margin-right: auto;
	margin-left: auto;
}

.ex-row {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
	margin-left: -@ex-grid-gutter-lg;
	margin-right: -@ex-grid-gutter-lg;
}

.ex-col-16,
.ex-col-25,
.ex-col-33,
.ex-col-50,
.ex-col-66,
.ex-col-75,
.ex-col-100 {
	flex: 0 0 100%;
	max-width: 100%;

	min-height: 1px;
	padding-left: @ex-grid-gutter-sm;
	padding-right: @ex-grid-gutter-sm;

	.ex-mq(md; {
		padding-left: @ex-grid-gutter-md;
		padding-right: @ex-grid-gutter-md;
	});

	.ex-mq(xl; {
		padding-left: @ex-grid-gutter-lg;
		padding-right: @ex-grid-gutter-lg;
	});
}

.ex-col-16 {
	.ex-mq(sm; {
		flex: 0 0 15%;
		max-width: 15%;
	});
}

.ex-col-25 {
	.ex-mq(xs; {
		flex: 0 0 50%;
		max-width: 50%;
	});
	.ex-mq(sm; {
		flex: 0 0 25%;
		max-width: 25%;
	});
}

.ex-col-33 {
	.ex-mq(sm; {
		flex: 0 0 40%;
		max-width: 40%;
	});
}

.ex-col-50 {
	.ex-mq(sm; {
		flex: 0 0 50%;
		max-width: 50%;
	});
}

.ex-col-66 {
	.ex-mq(sm; {
		flex: 0 0 60%;
		max-width: 60%;
	});
}

.ex-col-75 {
	.ex-mq(sm; {
		flex: 0 0 75%;
		max-width: 75%;
	});
}

.ex-col-100 {
	flex: 0 0 100%;
	max-width: 100%;
}

.ex-col-push-16 {
	.ex-mq(sm; {
		margin-left: 15%;
	});
}

.ex-col-push-25 {
	.ex-mq(sm; {
		margin-left: 25%;
	});
}

.ex-col-push-33 {
	.ex-mq(sm; {
		margin-left: 40%;
	});
}

.ex-col-push-50 {
	.ex-mq(sm; {
		margin-left: 50%;
	});
}

.ex-col-push-66 {
	.ex-mq(sm; {
		margin-left: 60%;
	});
}

.ex-col-push-75 {
	.ex-mq(sm; {
		margin-left: 75%;
	});
}
