// //-------------------------------------
// //
// // Utility classes
// //
// //-------------------------------------

// Positioning

.ex-pos-absolute {
	position: absolute !important;
}

.ex-pos-relative {
	position: relative !important;
}

// Display

.ex-display-flex {
	display: flex !important;
}

// Color

.ex-color-red {
	color: @ex-color-red !important;
}

// Spacing

// @ex-spacing-xs: 0.5rem;
// @ex-spacing-sm: 1rem;
// @ex-spacing-md: 2rem;
// @ex-spacing-lg: 4rem;
// @ex-spacing-xl: 8rem;
// @ex-spacing-xxl: 16rem;

@exSpacingMapLg: xs 0.5rem, sm 1rem, md 2rem, lg 4rem, xl 8rem, xxl 16rem, none 0;
@exSpacingMapSm: xs 0.25rem, sm 0.5rem, md 1rem, lg 2rem, xl 4rem, xxl 6rem, none 0;

.ex-generate-spacing(@array, @i: 1) when (@i <= length(@array)) {
	@pair: extract(@array, @i);
	@name: extract(@pair, 1);
	@value: extract(@pair, 2);

	// Padding

	.ex-padding-@{name} {
		padding: @value !important;
	}

	.ex-padding-top-@{name} {
		padding-top: @value !important;
	}

	.ex-padding-right-@{name} {
		padding-right: @value !important;
	}

	.ex-padding-bottom-@{name} {
		padding-bottom: @value !important;
	}

	.ex-padding-left-@{name} {
		padding-left: @value !important;
	}

	// Margins

	.ex-margin-@{name} {
		margin: @value !important;
	}

	.ex-margin-top-@{name} {
		margin-top: @value !important;
	}

	.ex-margin-right-@{name} {
		margin-right: @value !important;
	}

	.ex-margin-bottom-@{name} {
		margin-bottom: @value !important;
	}

	.ex-margin-left-@{name} {
		margin-left: @value !important;
	}

	// Negative Margins

	.ex-margin-top-neg-@{name} {
		margin-top: (-1 * @value) !important;
	}

	.ex-margin-right-neg-@{name} {
		margin-right: (-1 * @value) !important;
	}

	.ex-margin-bottom-neg-@{name} {
		margin-bottom: (-1 * @value) !important;
	}

	.ex-margin-left-neg-@{name} {
		margin-left: (-1 * @value) !important;
	}

	// Recursive...
	.ex-generate-spacing(@array, @i + 1);
}

// Do generate
.ex-generate-spacing(@exSpacingMapLg);

@media screen and (max-width: 720px) {
	.ex-generate-spacing(@exSpacingMapSm);
}

@media screen and (max-width: 840px) {
	.ex-margin-top-neg-xs,
	.ex-margin-top-neg-sm,
	.ex-margin-top-neg-md,
	.ex-margin-top-neg-lg,
	.ex-margin-top-neg-xl,
	.ex-margin-top-neg-xxl {
		margin-top: 0 !important;
	}

	.ex-margin-bottom-neg-xs,
	.ex-margin-bottom-neg-sm,
	.ex-margin-bottom-neg-md,
	.ex-margin-bottom-neg-lg,
	.ex-margin-bottom-neg-xl,
	.ex-margin-bottom-neg-xxl {
		margin-bottom: 0 !important;
	}
}
