// ------------------------------------
//
// Spinning spiders (and other creep)
//
// ------------------------------------

.ex-spinner-col.ex-col-75 {
	flex: 0 0 100%;
	max-width: 100%;

	.ex-mq(md; {
		flex: 0 0 85%;
		max-width: 85%;
	});

	.ex-mq(lg; {
		flex: 0 0 75%;
		max-width: 75%;
	});

	&.ex-col-push-25 {
		margin-left: 0;

		.ex-mq(md; {
			margin-left: 15%;
		});

		.ex-mq(lg; {
			margin-left: 25%;
		});
	}
}

.ex-spinner-spiderweb-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 0 -2rem;

	.ex-mq(md; {
		margin: 0;
	});

	&::before {
		content: '';
		padding-top: (791 / 800) * 100%; // Spiderweb's aspect ratio
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.ex-spinner-spiderweb-inner {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
}

.ex-spinner {
	flex: 0 0 14rem;
	cursor: pointer;
	z-index: @ex-elevation-task;
	position: relative;
	display: block;
	margin: 1rem 1rem 3rem;

	.ex-mq(md; {
		flex: 0 0 15rem;
	});

	&:nth-child(even) {
		margin-right: auto;

		.ex-mq(md; {
			margin-right: 1rem;
			margin-top: 2rem;
		});

		.ex-mq(lg; {
			margin-top: 5rem;
			margin-right: 7rem; // Force a line-break
		});
	}

	&:nth-child(odd) {
		margin-left: auto;

		.ex-mq(md; {
			margin-left: 1rem;
		});
	}

	&.is-spun {
		z-index: @ex-elevation-task-open;
	}

	&.ex-spinner-has-long-heading {
		// margin-bottom: @ex-spinner-heading-long-offset;
	}

	.ex-text-xs,
	.ex-text-md {
		margin-bottom: 0;
	}
}

.ex-spinner-spiderweb {
	color: @ex-color-red;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	height: auto;
	vertical-align: top;
	width: 200%;
	opacity: 0.5;

	.ex-mq(xs; {
		width: 100%;
		opacity: 1;
	});
}

.ex-spinner-animal,
.ex-spinner-heading {
	transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform: rotate(0) scale(1);
}

.ex-spinner-animal-reverse {
	transform: rotate(-180deg);
}

.ex-spinner-heading {
	.ex-heading-size-5;
	line-height: 1.2 !important;
	width: 90%;
	position: absolute;
	left: 0;
	top: 90%;
	transform-origin: center top;

	.ex-spinner-has-long-heading & {
		width: 110%;
		top: 80%;
	}
}

.ex-spinner-front {
	position: relative;
	padding: 0 3rem 0 1rem;

	// Spun state

	.ex-spinner.is-spun & {
		.ex-spinner-animal {
			transform: rotate(360deg) scale(0);
		}

		.ex-spinner-heading {
			transform: rotate(360deg) scale(0);
		}
	}

	// Hovered state

	.ex-spinner:not(.is-spun):hover & {
		.ex-spinner-animal {
			transform: rotate(2deg) scale(0.95);
		}

		.ex-spinner-animal-reverse {
			transform: rotate(-178deg) scale(0.95);
		}

		.ex-spinner-heading {
			transform: rotate(-2deg) scale(1.02);
		}
	}
}

.ex-spinner-back {
	position: absolute;
	top: 0;
	width: 100%;
	visibility: hidden;
	-webkit-visibility: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	overflow: hidden;
	padding: 1rem;
	color: @ex-color-text;
	background-color: @ex-color-white;
	transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform: rotate(-960deg) scale(0);

	// Spun state

	.ex-spinner.is-spun & {
		transform: rotate(0) scale(1);
		visibility: visible;
		-webkit-visibility: visible;
		box-shadow: @ex-task-shadow-current;
	}
}
