// ------------------------------------
//
// Parallax Particles
//
// ------------------------------------

.ex-particle {
	will-change: transform;
	position: absolute;
	top: 0;
	left: 0;
	z-index: @ex-elevation-particle;
	width: 10rem;
	display: none !important;

	.ex-mq(xl; {
		display: block !important;
	});
}

.ex-particle-sm {
	width: 8rem;
}

.ex-particle-lg {
	width: 12rem;
}

.ex-particle-xl {
	width: 14rem;
}

// --------------------------------------
// Individual placement for each instance
// --------------------------------------

// Page 'Wanderausstellung'

.ex-particle-20 {
	top: auto;
	left: 0;
	bottom: 10%;
}

.ex-particle-21 {
	top: 10%;
	left: auto;
	right: 0;
}

// Page 'Telekommunikation'

.ex-particle-22 {
	top: 75%;
	left: 70%;
}

.ex-particle-23 {
	top: 5%;
	left: 10%;
}

.ex-particle-24 {
	top: 5%;
	left: 0;
}

.ex-particle-25 {
	top: 12%;
	left: 23%;
}

.ex-particle-26 {
	top: 15%;
	left: 65%;
}

.ex-particle-27 {
	top: 5%;
	left: 87%;
}

.ex-particle-28 {
	top: 15%;
	left: 90%;
}

.ex-particle-29 {
	top: 25%;
	left: -2%;
}

// Page 'Allgemeines Verbraucherrecht'

.ex-particle-30 {
	top: 125%;
	left: 48%;
}
