// ------------------------------------
//
// Hinged Leaves
//
// ------------------------------------

// Closed state
@hinge-front-rotation-closed-1: -70deg;
@hinge-front-rotation-closed-2: -55deg;
@hinge-front-rotation-closed-3: -60deg;

@hinge-back-rotation-closed-1: 70deg;
@hinge-back-rotation-closed-2: 55deg;
@hinge-back-rotation-closed-3: 60deg;

// Open states
@hinge-front-rotation-open-1: 32deg;
@hinge-front-rotation-open-2: 30deg;
@hinge-front-rotation-open-3: 35deg;

@hinge-back-rotation-open-1: 0deg;
@hinge-back-rotation-open-2: 0deg;
@hinge-back-rotation-open-3: 0deg;

// Hover states
@hinge-front-rotation-hover-1: -65deg;
@hinge-front-rotation-hover-2: -50deg;
@hinge-front-rotation-hover-3: -55deg;

.ex-hinge-col {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
}

.ex-hinge {
	flex: 0 0 auto;
	cursor: pointer;
	position: relative;
	display: block;
	z-index: @ex-elevation-task;
	margin: 0;
	width: 15rem;
	margin: 4rem 4.5rem 2.5rem 1rem;

	.ex-mq(xs; {
		margin: 4rem 4.5rem 2.5rem 2rem;
	});

	.ex-mq(md; {
		margin: 6rem 7rem 4rem 2rem;
	});

	.ex-mq(lg; {
		margin: 6rem 7rem 6rem 2rem;
	});

	&::before {
		content: '';
		display: block;
		padding-top: 60%;
	}

	&.is-open {
		z-index: @ex-elevation-task-open;
	}
}

.ex-hinge-sm {
	.ex-mq(md; {
		width: 14rem;
	});
}

.ex-hinge-lg {
	.ex-mq(md; {
		width: 17rem;
	});
}

.ex-hinge-front,
.ex-hinge-back,
.ex-hinge-heading {
	transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275),
		opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.ex-hinge-front {
	position: absolute;
	top: 0;
	left: 0;
	width: 45%;
	height: auto;
	transform-origin: 55% 0; // Nudge up a bit so the joint looks more natural
	transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-closed-1);

	.ex-mq(xs; {
		width: 50%;
	});

	.ex-mq(md; {
		width: 60%;
	});

	.ex-hinge-tilt-2 & {
		transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-closed-2);
	}

	.ex-hinge-tilt-3 & {
		transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-closed-3);
	}

	// Hovered states

	.ex-hinge:not(.is-open) &:hover {
		transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-hover-1);
	}

	.ex-hinge.ex-hinge-tilt-2:not(.is-open) &:hover {
		transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-hover-2);
	}

	.ex-hinge.ex-hinge-tilt-3:not(.is-open) &:hover {
		transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-hover-3);
	}

	// Opened states

	.ex-hinge.is-open & {
		transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-open-1);
	}

	.ex-hinge.ex-hinge-tilt-2.is-open & {
		transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-open-2);
	}

	.ex-hinge.ex-hinge-tilt-3.is-open & {
		transform: translate(-50%, 0) rotate3d(0, 0, 1, @hinge-front-rotation-open-3);
	}
}

.ex-hinge-back {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: auto;
	padding: 1rem;
	opacity: 0;
	visibility: hidden;
	transform-origin: left top;
	transform: translate(0, 0) rotate3d(0, 0, 1, @hinge-back-rotation-closed-1);

	.ex-hinge-tilt-2 & {
		transform: translate(0, 0) rotate3d(0, 0, 1, @hinge-back-rotation-closed-2);
	}

	.ex-hinge-tilt-3 & {
		transform: translate(0, 0) rotate3d(0, 0, 1, @hinge-back-rotation-closed-3);
	}

	// Opened states

	.ex-hinge.is-open & {
		opacity: 1;
		visibility: visible;
		transform: translate(0, 0) rotate3d(0, 0, 1, @hinge-back-rotation-open-1);
		box-shadow: @ex-task-shadow-current;
	}

	.ex-hinge-tilt-2.is-open & {
		transform: translate(0, 0) rotate3d(0, 0, 1, @hinge-back-rotation-open-2);
	}

	.ex-hinge-tilt-3.is-open & {
		transform: translate(0, 0) rotate3d(0, 0, 1, @hinge-back-rotation-open-3);
	}

	.ex-text-xs,
	.ex-text-md {
		margin-bottom: 0;
	}
}

.ex-hinge-heading {
	.ex-heading-size-5;
	.padded-text(@color: @ex-color-blue, @bg: @ex-color-white, @padding: 0);
	line-height: 1.2 !important;
	line-height: 1.2;
	position: absolute;
	left: 0;
	bottom: 100%;
	width: 14rem;
	transform-origin: 0% 50%;
	transform: translate(0%, 150%) rotate3d(0, 0, 1, -@hinge-front-rotation-closed-1);

	.ex-mq(md; {
		width: 16rem;
	});

	.ex-hinge-tilt-2 & {
		transform: translate(0%, 250%) rotate3d(0, 0, 1, -@hinge-front-rotation-closed-2);
	}

	.ex-hinge-tilt-3 & {
		transform: translate(0%, 250%) rotate3d(0, 0, 1, -@hinge-front-rotation-closed-3);
	}

	// Hover states

	.ex-hinge:not(.is-open) .ex-hinge-front:hover & {
		transform: translate(0%, 150%) rotate3d(0, 0, 1, -@hinge-front-rotation-hover-1);
	}

	.ex-hinge.ex-hinge-tilt-2:not(.is-open) .ex-hinge-front:hover & {
		transform: translate(0%, 250%) rotate3d(0, 0, 1, -@hinge-front-rotation-hover-2);
	}

	.ex-hinge.ex-hinge-tilt-3:not(.is-open) .ex-hinge-front:hover & {
		transform: translate(0%, 250%) rotate3d(0, 0, 1, -@hinge-front-rotation-hover-3);
	}

	// Opened states

	.ex-hinge.is-open & {
		// transform: translate(0%, 25%) rotate3d(0, 0, 1, -@hinge-front-rotation-open-1);
		transform: translate(10%, -14%) rotate3d(0, 0, 1, -@hinge-front-rotation-open-1);
	}

	.ex-hinge-tilt-2.is-open & {
		// transform: translate(0%, 25%) rotate3d(0, 0, 1, -@hinge-front-rotation-open-2);
		transform: translate(10%, -14%) rotate3d(0, 0, 1, -@hinge-front-rotation-open-2);
	}

	.ex-hinge-tilt-3.is-open & {
		// transform: translate(0%, 25%) rotate3d(0, 0, 1, -@hinge-front-rotation-open-3);
		transform: translate(10%, -14%) rotate3d(0, 0, 1, -@hinge-front-rotation-open-3);
	}
}

.ex-hinge-leaf {
	display: block;
	width: 100%;
	height: auto;
	vertical-align: top;
}
