// ------------------------------------
//
// Base
//
// ------------------------------------

// Reset pre-existing site-wide styles

.ex-scope {
	font: 500 1rem/1.5 'Zilla Slab';

	&,
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	img {
		display: block;
		max-width: 100%;
		height: auto;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		padding: 0;
		color: inherit;
		font-family: inherit;
	}

	a {
		color: inherit;
		transition: all 0.2s;
	}
}
