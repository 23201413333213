// ------------------------------------
//
// Turning Timetable
//
// ------------------------------------

@ex-turn-width: 16rem;

.ex-turn-col {
	padding-left: 0;
	padding-right: 0;
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
}

.ex-turn-scrub-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&::before {
		content: '';
		padding-top: (420 / 400) * 100%;
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.ex-turn-scrub-container-inner {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
}

.ex-turn-scrub {
	color: @ex-color-green;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 100%;
	height: auto;
	vertical-align: top;
	padding: 0 1rem;
}

.ex-turn {
	cursor: pointer;
	position: relative;
	display: block;
	margin: 1rem;
	z-index: @ex-elevation-task;
	width: @ex-turn-width;

	&:nth-child(1) {
		margin-top: 5rem;
	}

	&.is-turned {
		z-index: @ex-elevation-task-open;
	}

	.ex-text-xs,
	.ex-text-md {
		margin-bottom: 0;
		text-align: center;
	}
}

.ex-turn-top {
	display: block;
	width: 100%;
}

.ex-turn-inner {
	position: relative;
}

.ex-turn-heading {
	.ex-heading-base();
	font-size: 1.25rem;
	line-height: 1.25;
	text-align: center;
	transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	opacity: 1;

	.ex-turn.is-turned & {
		opacity: 0;
	}
}

.ex-turn-back {
	display: flex;
	align-items: center;
	position: relative;
	overflow: hidden;
	padding: 2rem 1rem;

	&::before {
		content: '';
		padding-top: 80%;
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.ex-turn-front {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	transform-style: preserve-3d;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem 1rem;
	color: @ex-color-purple;
	background-color: lighten(@ex-color-beige, 8%);
	transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	transform-origin: center top;
	transform: perspective(800px) rotateX(0);

	// Spun state

	.ex-turn.is-turned & {
		transform: perspective(800px) rotateX(170deg) skewX(0);
		background-color: darken(@ex-color-beige, 2%);
	}

	// Hovered state

	.ex-turn:not(.is-turned):hover & {
		transform: perspective(800px) rotateX(5deg);
	}
}

.ex-turn-back {
	color: @ex-color-text;
	background-color: lighten(@ex-color-beige, 8%);

	// Spun state

	.ex-turn.is-turned & {
	}

	// Hovered state

	.ex-turn:not(.is-turned):hover & {
	}
}
